



























































import { Getter, Action } from 'vuex-class';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { parse, format, setGlobalDateI18n } from 'fecha';
// @ts-ignore
import { debounce } from 'lodash';
function Debounce(delay: number) {
    return (target: any, prop: string) => {
        return {
            configurable: true,
            enumerable: false,
            value: debounce(target[prop], delay),
        };
    };
}

@Component({
    components: {
        TableBase: () => import('@/components/table/TableBase.vue'),
        OrderSlideout: () => import('@/components/order/OrderSlideout.vue'),
        Spinner: () => import('@/components/elements/Spinner.vue'),
    },
})
export default class SupervisorOrderHistory extends Vue {
    @Getter('order/all') orders?: Order[];
    @Getter('pageLoading') pageLoading!: boolean;
    // pageLoading: boolean = true;

    @Action('order/index') indexOrders!: (payload: { companyId: number; q?: string; after: Array<string | number> | null }) => Promise<Order[]>;

    @Prop() companyId?: number;

    i18n: string = 'views.company';

    searchString: string = '';

    $refs!: {
        orderList: HTMLElement;
    };

    filter = {
        searchString: '',
    };

    localOrders: Order[] = [];

    visibleOrder: Order | null = null;

    tableLayout = {
        settings: {
            locale_path: `${this.i18n}.table`,
            max_height: 'calc(100% - 110px)',
            clickable: true,
        },
        structure: [
            {
                key: this.$t(`${this.i18n}.order_history.table.columns.order_number`),
                value: (model: Order) => model.id,
            },
            {
                key: this.$t(`${this.i18n}.order_history.table.columns.date`),
                value: (model: Order) => format(parse(model.created_at, 'isoDateTime')!, 'D MMM YYYY'),
            },
            {
                key: this.$t(`${this.i18n}.order_history.table.columns.amount`),
                value: (model: Order) => `${model.order_lines.length} ${model.order_lines.length > 1 ? 'producten' : 'product'}`,
            },
            {
                key: this.$t(`${this.i18n}.order_history.table.columns.placed_by`),
                value: (model: Order) => `${model.creator.first_name} ${model.creator.last_name}`,
            },
            {
                key: '',
                value: (model: Order) => `<span class="text-blue-dark border-blue-dark hover:border-transparent transition-colors border-b">Bestelling bekijken</span>`,
            },
        ],
    };

    created() {
        setGlobalDateI18n({
            monthNamesShort: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
        });
    }

    mounted() {
        this.$nextTick(() => {
            const self = this;
            this.initOrderList();
            window.addEventListener('resize', () => self.initOrderList());
            window.addEventListener('orientationchange', () => self.initOrderList());
        });
    }

    initOrderList() {
        window.addEventListener('scroll', this.orderScroll);
    }

    orderScroll(e: Event) {
        const productList: HTMLElement | undefined = this.$refs.orderList;
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

        if (productList) {
            if (window.scrollY + vw >= productList.getBoundingClientRect().bottom + window.scrollY) {
                this.loadMore(true);
            }
        }
    }

    @Watch('orders', { immediate: true })
    productsChanged(val: Order[]) {
        this.localOrders = val;
    }

    @Debounce(500)
    loadMore(after: boolean) {
        if (this.companyId) {
            this.indexOrders({ companyId: this.companyId, q: this.filter.searchString, after: after ? this.lastAfter : null });
        }
    }

    clearTextFilter() {
        this.filter.searchString = '';
        this.loadMore(false);
    }

    get lastAfter(): Array<string | number> {
        const total = this.localOrders.length;
        const last: Order = this.localOrders[total - 1];
        if (last && last.sort) {
            return last.sort;
        }

        return [];
    }
    private handleSearch() {
        if (!this.searchString) {
            return;
        }
    }
}
